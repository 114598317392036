import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyCXM4N70RuoBdifvHrwbQhoj1l43fhPYJ0',
  authDomain: 'mining-options.firebaseapp.com',
  projectId: 'mining-options',
  storageBucket: 'mining-options.appspot.com',
  messagingSenderId: '422612179062',
  appId: '1:422612179062:web:1418f0d3cec7891304e757',
  measurementId: 'G-YR95CKC6GQ',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics and export if needed
export const analytics = getAnalytics(app);

export const auth = getAuth(app);
// Export the app instance
export default app;
