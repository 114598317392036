import Layout from '@components/layout';
import './style.css';

import Tabs from '@components/tabs';
import SwipeableComponent from '@components/swipeable';
import { useState } from 'react';
import Buttom from '@components/buttons';

interface StakeInfoItem {
  label: string;
  value: string;
}

interface StakeInfoProps {
  items: StakeInfoItem[];
}

interface StakeFinishedInfoProps {
  items: {
    day: string;
    usdt: string;
    date: string;
  }[];
}
function App() {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [activeSwipeView, setActiveSwipeView] = useState<number>(0);

  const Started: React.FC<StakeInfoProps> = ({ items }) => {
    return (
      <>
        <center>
          <p>
            <b>1d</b>
          </p>
        </center>
        <div className="stake-info">
          {items.map((item, index) => (
            <div className="stake-info-item" key={index}>
              <div className="stake-info-label">{item.label}</div>
              <div className="stake-info-value">{item.value}</div>
            </div>
          ))}
        </div>
        <br />
        <center>
          <Buttom label="Cancel Stake" size="lg" />
        </center>
        <br />
        <br />
        <p>
          <b>10d</b>
        </p>
        <div className="stake-info">
          {items.map((item, index) => (
            <div className="stake-info-item" key={index}>
              <div className="stake-info-label">{item.label}</div>
              <div className="stake-info-value">{item.value}</div>
            </div>
          ))}
        </div>
        <br />
        <center>
          <Buttom label="Cancel Stake" size="lg" />
        </center>
      </>
    );
  };

  const Finished: React.FC<StakeFinishedInfoProps> = ({ items }) => {
    return (
      <div className="stake-info">
        {items.map((item, index) => (
          <div className="stake-info-item" key={index}>
            <div className="stake-info-label">
              <span className="stake-info-icon"></span> {item.day}
            </div>
            <div className="stake-info-value">{item.usdt}</div>
            <div className="stake-info-value">{item.date}</div>
          </div>
        ))}
      </div>
    );
  };

  const itemsSummary = [
    {
      label: 'Real time balance',
      value: '0.00 USDT',
    },
    {
      label: 'Expected final balance',
      value: '0.00 USDT',
    },
  ];

  const itemsFinish = [
    {
      day: '1d',
      usdt: '+ 0.00 USDT',
      date: '2024-01-01',
    },
    {
      day: '1d',
      usdt: '+ 0.00 USDT',
      date: '2024-01-01',
    },
    {
      day: '1d',
      usdt: '+ 0.00 USDT',
      date: '2024-01-01',
    },
    {
      day: '1d',
      usdt: '+ 0.00 USDT',
      date: '2024-01-01',
    },
    {
      day: '1d',
      usdt: '+ 0.00 USDT',
      date: '2024-01-01',
    },
    {
      day: '1d',
      usdt: '+ 0.00 USDT',
      date: '2024-01-01',
    },
    {
      day: '1d',
      usdt: '+ 0.00 USDT',
      date: '2024-01-01',
    },
    {
      day: '1d',
      usdt: '+ 0.00 USDT',
      date: '2024-01-01',
    },
    {
      day: '1d',
      usdt: '+ 0.00 USDT',
      date: '2024-01-01',
    },
  ];

  const tabs = [
    {
      title: 'Started',
    },
    {
      title: 'Finished',
    },
  ];

  const handleIndexChange = (index: number) => {
    setActiveTab(index);
    setActiveSwipeView(index);
  };

  const handleIndexChangeTab = (index: number) => {
    setActiveSwipeView(index);
  };

  const components = [
    <Started items={itemsSummary} />,
    <Finished items={itemsFinish} />,
  ];

  return (
    <Layout showNotification={false} showNavMobile={false}>
      <div id="stake-summary">
        <div id="content-tabs-stake">
          <Tabs
            tabs={tabs}
            activeTab={activeTab}
            onIndexChange={handleIndexChangeTab}
          />
        </div>

        <SwipeableComponent
          currentItem={activeSwipeView}
          components={components}
          onIndexChange={handleIndexChange}
        />
      </div>
    </Layout>
  );
}

export default App;
