import RootNavigator from '@navigation/root';
import { Provider } from 'react-redux';
import { store } from '@redux/store';
import { ToastProvider } from '@context/ToastContext';

import './App.css';

function App() {
  return (
    <Provider store={store}>
      <ToastProvider>
        <RootNavigator />
      </ToastProvider>
    </Provider>
  );
}

export default App;
