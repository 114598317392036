import { useEffect } from 'react';
import { getAnalytics } from 'firebase/analytics';
import app from './firebase';

const useFirebase = () => {
  useEffect(() => {
    getAnalytics(app);
  }, []);

  return app;
};

export default useFirebase;
