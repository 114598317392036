import Layout from '@components/layout';
import './style.css';
import TelegramLogin from '@components/auth/telegram';
import { AuthProps } from 'types/auth';
import { AppStateType } from '@redux/store';
import { useSelector } from 'react-redux';
import GoogleBtnAuth from '@components/auth/google';
import AppleBtnAuth from '@components/auth/apple';
import { linkingAccount } from '@hooks/useAuth';
import { useToast } from '@context/ToastContext';

function App() {
  const { user } = useSelector((state: AppStateType) => state.initLoad);

  const { showToast } = useToast();

  const authProiver = (data: AuthProps) => {
    data.user_current_id = user.id;

    linkingAccount(data)
      .then(() => {
        showToast('Operación exitosa!', 'success');

        setTimeout(() => {
          window.location.href = '/';
        }, 3000);
      })
      .catch(() => {
        showToast('The account already exists', 'error');
      });
  };

  return (
    <Layout
      icon={false}
      showBottomNav={false}
      showNotification={false}
      showNavMobile={false}
    >
      <div id="linking-accounts">
        <div className="linking-accounts-content">
          {user.liking_account ? (
            <div className="list-linking-account">
              <div>
                {user.liking_account.provider === 'google' ? (
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/480px-Google_%22G%22_logo.svg.png"
                    alt="google"
                  />
                ) : user.liking_account.provider === 'apple' ? (
                  <img
                    src="https://ourdeal.co.uk/wp-content/uploads/2023/04/Why-Cant-I-Sign-into-My-Apple-ID-How-Can-I-Solve-This.jpg"
                    alt="facebook"
                  />
                ) : (
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/2048px-Telegram_logo.svg.png"
                    alt="facebook"
                  />
                )}
              </div>
              <div>{user.liking_account.name}</div>
              <div>{user.liking_account.email}</div>
            </div>
          ) : (
            <>
              {user.provider !== 'telegram' ? (
                <TelegramLogin authMethod={authProiver} />
              ) : (
                <div className="social-login">
                  <GoogleBtnAuth authMethod={authProiver} />
                  <AppleBtnAuth authMethod={authProiver} />
                </div>
              )}
            </>
          )}
        </div>
        <br />
      </div>
    </Layout>
  );
}

export default App;
