import Layout from '@components/layout';
import './style.css';
import { useState } from 'react';
import Tabs from '@components/tabs';
import Buttom from '@components/buttons';
import { InputText } from '@components/forms';
import SwipeableComponent from '@components/swipeable';
import { useWallet } from '@hooks/useWallet';
import { Transaction } from 'types/wallet';

function App() {
  const { data } = useWallet();

  console.log({ data });
  const [deposit, setDeposit] = useState<boolean>(false);

  const [activeTab, setActiveTab] = useState<number>(0);
  const [activeSwipeView, setActiveSwipeView] = useState<number>(0);

  const EyeBalance = ({ amount }: { amount: string }) => {
    const [hide, setHide] = useState<boolean>(true);

    return (
      <>
        <span onClick={() => setHide(!hide)} className="info-icon">
          {hide ? (
            <i className="fas fa-eye"></i>
          ) : (
            <i className="fas fa-eye-slash"></i>
          )}
        </span>
        {hide ? (
          <div className="balance-amount">{amount} USDT</div>
        ) : (
          <div className="balance-amount">*******</div>
        )}
      </>
    );
  };

  const ListData = ({ data }: { data: Transaction[] }) => {
    const render = data.map((item: any) => {
      return (
        <div style={{ width: '95%', margin: '0 auto' }}>
          <div className="listItem">
            <div>{item.type}</div>
            <div>{`${item.amount} ${item.description}`}</div>

            <div>Done</div>
          </div>
        </div>
      );
    });

    return <>{render}</>;
  };

  const InProgress = () => {
    return (
      <div className="transaction-details">
        <p className="transaction-amount">Withdraw 0.00 USDT</p>
        <p className="transaction-address">
          <b>To:</b> TNge83uUupVAL3ac1Vc1NBLpokNno828Kh
        </p>
        <div className="transaction-dates">
          <span>
            <b>Date:</b> 24-01-01 0:00
          </span>
        </div>
        <br />
        <div className="transaction-dates">
          <span>
            <b>Arrival deadline: </b>24-01-01 0:00
          </span>
        </div>
      </div>
    );
  };

  const tabs = [
    {
      title: 'Successfull',
    },
    {
      title: 'In progress',
    },
  ];

  const handleIndexChange = (index: number) => {
    setActiveTab(index);
    setActiveSwipeView(index);
  };

  const handleIndexChangeTab = (index: number) => {
    setActiveSwipeView(index);
  };

  const components = [
    <ListData data={data ? data.transactions : []} />,
    <InProgress />,
  ];

  return (
    <Layout title="WALLET" showBottomNav={true} showGoBack={false}>
      <div id="wallet">
        <div className="balance-container">
          <div className="balance-section">
            <span className="balance-label">Available balance</span>
            <EyeBalance amount={data ? data.balance : '0.0'} />
          </div>
          <div className="balance-section">
            <span className="balance-label">Stake balance</span>
            <EyeBalance amount="0" />
          </div>
        </div>

        <div className="transactions">
          <h3>Transaction history</h3>

          <Tabs
            tabs={tabs}
            activeTab={activeTab}
            onIndexChange={handleIndexChangeTab}
          />

          <SwipeableComponent
            currentItem={activeSwipeView}
            components={components}
            onIndexChange={handleIndexChange}
          />

          <br />

          <div className="content-buttons-wallet">
            <Buttom label="DEPOSIT" size="lg" />
            <Buttom
              label="WITHDRAW"
              size="lg"
              onClick={() => setDeposit(!deposit)}
            />
          </div>

          <br />
          <br />

          {!deposit ? (
            <h2 className="text-center deposit-only">
              DEPOSIT ONLY USDT TRC20
            </h2>
          ) : (
            <div style={{ paddingInline: 20 }}>
              <InputText
                placeholder="Minimum 2.00 USDT"
                size="md"
                value=""
                label="Select Amount"
                action="MAX"
              />
              <br />
              <InputText
                placeholder="Network fee 1.00 USDT + 2% comissions"
                size="md"
                value=""
                label="Usdt TRC-20 wallet"
              />
              <br />
              <center>
                <Buttom icon="fas fa-check" size="lg" />
              </center>
            </div>
          )}

          <br />
          <br />

          <center className="text-support">
            <i className="fas fa-envelope"></i>
            <p>support@minigoptions.net</p>
          </center>
        </div>
      </div>
    </Layout>
  );
}

export default App;
