import { useSelector } from 'react-redux';
import './style.css';
import { AppStateType } from '@redux/store';

function App() {
  const { user } = useSelector((state: AppStateType) => state.initLoad);

  return (
    <div className="contentHeader">
      {user.photo_profile ? (
        <img className="avatarImage" src={user.photo_profile} alt="" />
      ) : (
        <div>
          <i className="fas fa-user"></i>
        </div>
      )}

      <h5>{user.username}</h5>
    </div>
  );
}

export default App;
