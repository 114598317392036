import { useEffect, useState } from 'react';
import Buttom from '@components/buttons';
import { InputText } from '@components/forms';
import { ReactComponent as BinanceIcon } from '@assets/icons/binance.svg';
import { useToast } from '@context/ToastContext';
import './style.css';
import { useExtraTasks } from '@hooks/useExtraTask';

function App() {
  const { showToast } = useToast();
  const { store, data } = useExtraTasks();
  const [postLinkBinance, setPostLinkBinance] = useState<string>('123');
  const [postLinkYoutube, setPostLinkYoutube] = useState<string>('');

  const [statusBinance, setStatusBinance] = useState<
    'Pending' | 'Done' | 'none'
  >('none');

  const [statusYoutube, setStatusYoutube] = useState<
    'Pending' | 'Done' | 'none'
  >('none');

  useEffect(() => {
    if (data.length > 0) {
      data.forEach((item) => {
        switch (item.provider) {
          case 'Binance':
            setStatusBinance(item.status);
            setPostLinkBinance(item.post_link);
            break;
          case 'Youtube':
            setStatusYoutube(item.status);
            setPostLinkYoutube(item.post_link);
            break;
          default:
            break;
        }
      });
    }
  }, [data]);

  const storeLinkPost = (provider: 'Binance' | 'Youtube') => {
    const postLinks = {
      Binance: postLinkBinance,
      Youtube: postLinkYoutube,
    };

    const errorMessages = {
      Binance: 'Post Link Binance field is required.',
      Youtube: 'Post Link Youtube field is required.',
    };

    if (postLinks[provider] === '') {
      showToast(errorMessages[provider], 'error');
      return;
    }

    const data = {
      provider,
      post_link: postLinks[provider],
    };
    store(data).then(() => {
      if (provider === 'Binance') {
        setStatusBinance('Pending');
      }

      if (provider === 'Youtube') {
        setStatusYoutube('Pending');
      }
      showToast('Operación exitosa', 'success');
    });
  };

  return (
    <div className="task-list">
      <div className="extra-task-list">
        <p>
          Post about Mining Options on Binance Square and tag us to get a 10%
          boost to the estimated profits of a selected stake
        </p>
        <div className="extra-task-item">
          <div className="extra-task-content-input">
            <div className="content-icon-extra-task">
              <BinanceIcon />
            </div>
            <InputText
              value={postLinkBinance}
              disabled={
                statusBinance === 'Pending' || statusBinance === 'Done'
                  ? true
                  : false
              }
              placeholder="Binance post link"
              size="xs"
              onChange={setPostLinkBinance}
            />
          </div>

          {statusBinance === 'none' ? (
            <Buttom
              icon="fas fa-check"
              size="md"
              onClick={() => {
                storeLinkPost('Binance');
              }}
            />
          ) : (
            <Buttom disabled={true} label={statusBinance} size="md" />
          )}
        </div>

        <center>
          <Buttom label="Boost Stake" size="lg" disabled={true} />
        </center>

        <br />

        <p>
          Post about Mining Options on Youtube to get a 10% boost to the
          estimated profits of a selected stake
        </p>

        <div className="extra-task-item">
          <div className="extra-task-content-input">
            <div className="content-icon-extra-task">
              <i
                className={`task-icon fab fa-youtube`}
                style={{ marginLeft: '-4px' }}
              ></i>
            </div>
            <InputText
              value={postLinkYoutube}
              disabled={
                statusYoutube === 'Pending' || statusYoutube === 'Done'
                  ? true
                  : false
              }
              placeholder="Youtube post link"
              size="xs"
              onChange={setPostLinkYoutube}
            />
          </div>

          {statusYoutube === 'none' ? (
            <Buttom
              icon="fas fa-check"
              size="md"
              onClick={() => {
                storeLinkPost('Youtube');
              }}
            />
          ) : (
            <Buttom disabled={true} label={statusYoutube} size="md" />
          )}
        </div>
        <center>
          <Buttom label="Boost Stake" size="lg" disabled={true} />
        </center>
      </div>
    </div>
  );
}

export default App;
