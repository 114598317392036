import Layout from '@components/layout';
import './style.css';
import Buttom from '@components/buttons';
import { InputText } from '@components/forms';
import Divider from '@components/divider';

function App() {
  const users = [
    { id: 1, name: 'User 1', balance: 0.0 },
    { id: 2, name: 'User 2', balance: 0.0 },
    { id: 3, name: 'User 3', balance: 0.0 },
    { id: 4, name: 'User 4', balance: 0.0 },
    { id: 4, name: 'User 5', balance: 0.0 },
    { id: 4, name: 'User 6', balance: 0.0 },
    { id: 4, name: 'User 7', balance: 0.0 },
    { id: 4, name: 'User 8', balance: 0.0 },
  ];

  return (
    <Layout
      title="REFERRALS"
      icon={false}
      showBottomNav={true}
      showGoBack={false}
    >
      <div id="frens">
        <div className="wallet-transfer">
          <div className="wallet-balance">
            <span className="balance-amount">
              {' '}
              <img
                style={{ width: '25px' }}
                src={
                  'https://static-00.iconduck.com/assets.00/tether-cryptocurrency-icon-2048x2048-dp13oydi.png'
                }
                alt=""
              />{' '}
              0.00
            </span>
          </div>
          <InputText
            placeholder="Minimum 1.00 USDT"
            size="md"
            value=""
            label="Transfer to your wallet:"
          />
          <br />
          <center>
            <Buttom size="lg" icon="fas fa-exchange-alt" />
          </center>
        </div>

        <br />

        <div style={{ width: '100%', margin: '0 auto' }}>
          <h3>Number of referrals: 0</h3>
        </div>
        <Divider />

        <div className="user-list-container">
          {users.map((user) => (
            <div key={user.id} className="user-row">
              <span className="user-name">{user.name}</span>
              <span className="user-balance">{user.balance.toFixed(2)}</span>
              <Buttom size="xs" label="Claim" />
            </div>
          ))}
        </div>

        <Divider />

        <div style={{ width: '100%', margin: '0 auto' }}>
          {/*<p className='text-center text-small'>Get a fixed 8% of your direct referrals</p>*/}
        </div>

        <center>
          <Buttom size="lg" label="CLAIM ALL" />
        </center>

        <br />
        <br />
        <div style={{ width: '100%', margin: '0 auto', position: 'relative' }}>
          <InputText
            value=""
            size="md"
            label="Referral link"
            placeholder="miningoptions.net/refer/123412sfas"
            disabled={true}
          />
          <div className="content-icon-copy">
            <i className="far fa-copy"></i>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default App;
