import LogoWhite from '@assets/logos/white.svg';
import './style.css';
import Buttom from '@components/buttons';
import { useNavigate } from 'react-router-dom';

const Index = () => {
  const navigate = useNavigate();

  return (
    <div className="launch-container">
      <div className="line-container">
        <div className="line-horizontal"></div>
        <div className="line-horizontal secondary"></div>
        <div className="line-vertical"></div>
      </div>
      <img src={LogoWhite} alt="MiningOptions" className="logoLaunch" />
      <br />
      <p>The most innovative and profitable staking options in a one app</p>

      <div className="launch-btns">
        <Buttom
          size="lg"
          label="Desktop Launch"
          onClick={() => {
            navigate('/sing-in');
          }}
        />
        <Buttom
          size="lg"
          label="Telegram Launch"
          onClick={() => {
            window.location.href = 'https://t.me/krlosexe_bot/Krlosexe';
          }}
        />
      </div>
    </div>
  );
};

export default Index;
