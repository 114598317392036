import { createSlice } from '@reduxjs/toolkit';
import { IAuthState, openMenu, initialState } from './types';

export const openMenuNavSlice = createSlice({
  name: 'initLoad',
  initialState: initialState as IAuthState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(openMenu, (state, action) => {
      console.log('initLoad:openMenu');
      state.openMenu = action.payload;
    });
  },
});
