export const tasks = [
  {
    id: 1,
    platform: 'Telegram',
    bonus: '+10%',
    icon: 'fab fa-telegram-plane',
    link: 'https://t.me/mining_options',
  },
  {
    id: 2,
    platform: 'Twitter',
    bonus: '+10%',
    icon: 'fab fa-twitter',
    link: 'https://x.com/mining_options?s=11&t=P5Q0Gh941v-xBEugpAY3lg',
    image: 'x.png',
  },
  {
    id: 3,
    platform: 'Instagram',
    bonus: '+10%',
    icon: 'fab fa-instagram',
    link: 'https://www.instagram.com/mining_options?igsh=MzRlODBiNWFlZA==',
  },
  {
    id: 4,
    platform: 'Youtube',
    bonus: '+10%',
    icon: 'fab fa-youtube',
    link: 'https://youtube.com/@mining_options?si=y6RqQmusDDybkmfc',
  },
  {
    id: 5,
    platform: 'Binance',
    bonus: '+10%',
    icon: 'fab fa-tiktok',
    link: 'https://www.binance.com/en/square/profile/mining_options',
    image: 'binance.svg',
  },
];
