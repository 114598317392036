import React, { useState, ReactNode, useEffect } from 'react';
import './style.css';

interface Tab {
  title: string;
  content?: ReactNode;
}

interface TabsProps {
  tabs: Tab[];
  activeTab: number;
  onIndexChange?: (index: number) => void; 
}

const Tabs: React.FC<TabsProps> = ({ tabs, activeTab, onIndexChange }) => {
  
  const [active, setActive] = useState<number>(activeTab);

  useEffect(() => {
    setActive(activeTab);
  }, [activeTab]);

  const handleTabClick = (index: number) => {
    setActive(index);
    if (onIndexChange) {
      onIndexChange(index);
    }
  };


  return (
    <div className="tabs">
      <div className="tab-headers">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab-header ${active === index ? 'active' : ''}`}
            onClick={() => handleTabClick(index)}
          >
            {tab.title}
          </div>
        ))}
               
      </div>
      <div className="tab-content">
        {tabs.map((tab, index) =>
          activeTab === index ? (
            <div key={index}>{tab.content}</div>
          ) : null
        )}
      </div>
    </div>
  );
};

export default Tabs;
