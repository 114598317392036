import React, { useState, useRef, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';

interface SwipeableComponentProps {
  components: React.ReactNode[];
  currentItem: number;
  onIndexChange?: (index: number) => void; 
}

const SwipeableComponent: React.FC<SwipeableComponentProps> = ({ components, onIndexChange, currentItem }) => {
  const [currentIndex, setCurrentIndex] = useState(currentItem);
  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState(0);
  const [swipeDirection, setSwipeDirection] = useState<'horizontal' | 'vertical'>('horizontal');
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCurrentIndex(currentItem);
  }, [currentItem]);

  useEffect(() => {
    setCurrentIndex(currentIndex);
  }, [currentIndex]);

  const handleSwipe = (direction: 'left' | 'right') => {
    setDragging(false);
    setOffset(0);
    if (direction === 'left' && currentIndex < components.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else if (direction === 'right' && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipe('left'),
    onSwipedRight: () => handleSwipe('right'),
    onSwiping: (eventData) => {
      // Determine swipe direction based on the magnitude of deltaX and deltaY
        if (Math.abs(eventData.deltaX) > Math.abs(eventData.deltaY)) {
          
        setSwipeDirection('horizontal');
        setDragging(true);
        setOffset(eventData.deltaX);
      } else {
        setSwipeDirection('vertical');
        setDragging(false);
      }
    },
    trackMouse: true,
  });

  useEffect(() => {
    if (onIndexChange) {
      onIndexChange(currentIndex);
    }
  }, [currentIndex, onIndexChange]);

  const getTransformStyle = () => {
    if (dragging && swipeDirection === 'horizontal') {
      return `translateX(calc(-${currentIndex * 100}% + ${offset}px))`;
    }
    return `translateX(-${currentIndex * 100}%)`;
  };

  return (
    <div {...handlers} style={{ overflow: 'hidden', width: '100%', display: 'flex', position: 'relative' }}>
      <div
              ref={containerRef}
              
        style={{
          display: 'flex',
          width: `${components.length * 100}%`,
          transform: getTransformStyle(),
          transition: dragging && swipeDirection === 'horizontal' ? 'none' : 'transform 0.3s ease',
        }}
      >
        {components.map((Component, index) => (
          <div key={index} style={{ flex: '0 0 100%', height: '100%' }}>
            {Component}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SwipeableComponent;
