import axios from 'axios';

export const TIMEOUT_TIME = 10000;

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const request = axios.create({
  baseURL: REACT_APP_API_URL,
  timeout: TIMEOUT_TIME,
});

request.interceptors.request.use(async (config) => {
  config.headers.Accept = 'application/json';
  const token = localStorage.getItem('@tokenAuth');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default request;
