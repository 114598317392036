
import './style.css';

const Divider = () => {
  return (
    <div className="divider" />
  );
};

export default Divider;
