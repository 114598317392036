import requests from 'helpers/requests';
import { useEffect, useState } from 'react';

type PostLinkData = {
  id: number;
  user_id: number;
  post_link: string;
  provider: 'Binance' | 'Youtube';
  status: 'Pending' | 'Done' | 'none';
  created_at: string;
  updated_at: string;
};

export const useExtraTasks = () => {
  const [data, setData] = useState<PostLinkData[]>([]);
  const store = async (data: { provider: string; post_link: string }) => {
    try {
      const response = await requests.post('extra/task', data);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.message || 'An unexpected error occurred';
      throw new Error(errorMessage);
    }
  };

  const get = async () => {
    try {
      const response = await requests.get('extra/task');
      setData(response.data);
    } catch (error: any) {}
  };

  useEffect(() => {
    get();
  }, []);

  return { data, store };
};
