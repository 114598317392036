import { createAction } from '@reduxjs/toolkit';

export interface IAuthState {
  openMenu: boolean;
}

export const initialState: IAuthState = {
  openMenu: false,
};

export const openMenu = createAction<boolean>('root/openMenu');
