import Layout from '@components/layout';
import './style.css';
import { InputText } from '@components/forms';
import Buttom from '@components/buttons';
import Tabs from '@components/tabs';
import SwipeableComponent from '@components/swipeable';
import { useState } from 'react';

interface StakeInfoItem {
  label: string;
  value: string;
  icon: string;
}

interface StakeInfoProps {
  items: StakeInfoItem[];
}

function App() {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [activeSwipeView, setActiveSwipeView] = useState<number>(0);

  const StakeSummary: React.FC<StakeInfoProps> = ({ items }) => {
    return (
      <div className="stake-info">
        {items.map((item, index) => (
          <div className="stake-info-item" key={index}>
            <div className="stake-info-label">
              <span className="stake-info-icon">
                <i className={item.icon}></i>
              </span>{' '}
              {item.label}
            </div>
            <div className="stake-info-value">{item.value}</div>
          </div>
        ))}
      </div>
    );
  };

  const StakeDetail: React.FC<StakeInfoProps> = ({ items }) => {
    return (
      <div className="stake-info">
        {items.map((item, index) => (
          <div className="stake-info-item" key={index}>
            <div className="stake-info-label">
              <span className="stake-info-icon">
                <i className={item.icon}></i>
              </span>{' '}
              {item.label}
            </div>
            <div className="stake-info-value">{item.value}</div>
          </div>
        ))}
        <p>
          Next restart date of maximum contract limit: <br />
          <br />
          2024-01-01: 00:00
        </p>
      </div>
    );
  };

  const itemsSummary = [
    {
      label: 'Stake Amount:',
      value: '0.00 USDT',
      icon: 'fas fa-cubes',
    },
    {
      label: 'Daily Reward:',
      value: '0.00 USDT',
      icon: 'fas fa-coins',
    },
    {
      label: 'Timer:',
      value: '00:00',
      icon: 'fas fa-clock',
    },
  ];

  const itemsDetail = [
    {
      label: 'Start Date:',
      value: '2024-01-01 00:00',
      icon: 'fas fa-calendar',
    },
    {
      label: 'Finish Date:',
      value: '2024-01-01 00:00',
      icon: 'fas fa-calendar',
    },
  ];

  const tabs = [
    {
      title: 'Summary',
    },
    {
      title: 'Details',
    },
  ];

  const handleIndexChange = (index: number) => {
    setActiveTab(index);
    setActiveSwipeView(index);
  };

  const handleIndexChangeTab = (index: number) => {
    setActiveSwipeView(index);
  };

  const components = [
    <StakeSummary items={itemsSummary} />,
    <StakeDetail items={itemsDetail} />,
  ];

  return (
    <Layout title="USDT CONTRACTS" showBottomNav={true} showGoBack={false}>
      <div id="stake">
        <div className="scroll-container">
          <div className="scroll-item active">
            <div className="label-day">1 d</div>
            <div>1.80%</div>
          </div>
          <div className="scroll-item">
            <div className="label-day">5 d</div>
            <div>2.20%</div>
          </div>
          <div className="scroll-item">
            <div className="label-day">10 d</div>
            <div>2.50%</div>
          </div>
          <div className="scroll-item">
            <div className="label-day">30 d</div>
            <div>2.80%</div>
          </div>

          <div className="scroll-item">
            <div className="label-day">90 d</div>
            <div>3.30%</div>
          </div>
          <div className="scroll-item">
            <div className="label-day">180 d</div>
            <div>3.80%</div>
          </div>
        </div>

        <div className="transaction-form">
          <div className="input-group">
            <InputText
              placeholder="Minimum 1.00 USDT"
              size="md"
              label="Amount"
              value=""
              action="MAX"
            />
          </div>
          <div className="details">
            <span className="content-available-stake">
              <span>
                Available: <b>0.00 USDT</b>
              </span>
              <div className="icon-transfer">
                <i className="fas fa-exchange-alt"></i>
              </div>
            </span>
            <span>
              Transaction Fee: <b>0.20 USDT</b>
            </span>
          </div>
          <div className="operation-limit">
            <span>
              {' '}
              Operation Limit: <b>0.00/0.00 USDT</b>
            </span>
            <div className="icon-transfer">
              <i className="far fa-question-circle"></i>
            </div>
          </div>
          <center>
            <Buttom label="CONFIRM" size="lg" />
          </center>
        </div>
        <br />
        <br />
        <div id="content-tabs-stake">
          <Tabs
            tabs={tabs}
            activeTab={activeTab}
            onIndexChange={handleIndexChangeTab}
          />
        </div>

        <SwipeableComponent
          currentItem={activeSwipeView}
          components={components}
          onIndexChange={handleIndexChange}
        />

        <center className="text-support-stake">
          <i className="fas fa-question-circle"></i>
        </center>
      </div>
    </Layout>
  );
}

export default App;
