import { AuthProps, TelegramUser } from 'types/auth';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '@hooks/useFirebase/firebase';

declare global {
  interface Window {
    onTelegramAuth: (user: TelegramUser) => void;
  }
}

type Props = {
  authMethod: (data: AuthProps) => void;
};
const GoogleBtnAuth = ({ authMethod }: Props) => {
  const provider = new GoogleAuthProvider();

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;

      if (credential) {
        console.log({ token });

        const user = result.user;

        const data = {
          provider: 'google',
          provider_user_id: user.uid,
          name: user.displayName,
          email: user.email,
          photoProfile: user.photoURL,
        };
        authMethod(data);
        //console.log('User:', data);
      }
    } catch (error) {
      console.error('Error during sign-in:', error);
    }
  };

  return (
    <button className="btn-provider google-login" onClick={signInWithGoogle}>
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/480px-Google_%22G%22_logo.svg.png"
        alt="google"
      />
      Google
    </button>
  );
};

export default GoogleBtnAuth;
