import requests from 'helpers/requests';
import { useEffect, useState } from 'react';
import { Wallet } from 'types/wallet';

export const useWallet = () => {
  const [data, setData] = useState<Wallet>();

  const get = async () => {
    try {
      const response = await requests.get('wallet');
      setData(response.data);
    } catch (error: any) {}
  };

  useEffect(() => {
    get();
  }, []);

  return { data };
};
