import { createAction } from '@reduxjs/toolkit';

type UserData = {
  id: number;
  username: string;
  photo_profile: string;
  provider: string;
  provider_user_id: string;
  liking_account: any;
  auth_telegram?: {
    user_id: string;
  };
};
export interface IAuthState {
  isFirstLoad: boolean;
  isSingin: boolean;
  user: UserData;
  showlogout: boolean;
}

export const initialState: IAuthState = {
  isFirstLoad: true,
  isSingin: false,
  showlogout: true,
  user: {
    id: 0,
    username: '',
    photo_profile: '',
    provider: '',
    provider_user_id: '',
    liking_account: null,
    auth_telegram: {
      user_id: '',
    },
  },
};

export const isFirstLoad = createAction<boolean>('root/isFirstLoad');
export const isSingin = createAction<boolean>('root/isSingin');
export const userData = createAction<UserData>('root/userData');
export const setShowLogout = createAction<boolean>('root/setShowLogout');
